import { post } from '@/utils/request'

//编辑权限
export function getAuthority(data) {
    return post('admin/post/authority', data)
}
//岗位选择
export function getEdit(data) {
    return post('admin/post/edit',data)
}
export function getAdd(data) {
    return post('admin/post/add', data)
}
export function getList(data) {
    return post('admin/post/list', data)
}
export function getOption() {
    return post('admin/department/option')
}